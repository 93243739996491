import noaprobado from 'img/fake/no-aprobada.svg';
import aprobado from 'img/fake/aprobada.svg';
import tramitacion from 'img/fake/tramitacion.svg';

const initiativesList = [
  { name: 'Proposición de ley' },
  { name: 'Propuesta de reforma constitucional' },
  { name: 'Propuesta de reforma de Estatuto de Autonomía' },
  { name: 'Proyecto de ley' },
  { name: 'Real decreto legislativo' },
  { name: 'Real decreto ley' },
];

const filterByList = {
  legislature: 'Legislatura',
  orderBy: 'Ordenar por',
  author: 'Autor',
  category: 'Categoría',
  type: 'Tipo de ley',
  processingResult: 'Estado',
  euDriven: 'Impulso UE',
};

const mainModal = [{ title: 'Main modal', data: 'mainModal', titleGenre: '' }];

const modalButtons = [
  { title: 'Proyecto de Ley', data: 'proyectoDeLey', titleGenre: 'm' },
  { title: 'Proposición de Ley', data: 'proposicionDeLey', titleGenre: 'f' },
  { title: 'Real Decreto Ley', data: 'realDecretoLey', titleGenre: 'm' },
  {
    title: 'Real Decreto Legislativo',
    data: 'realDecretoLegislativo',
    titleGenre: 'm',
  },
  {
    title: 'Reforma del Estatuto de Autonomía',
    data: 'reformaEstatoAutonomía',
    titleGenre: 'f',
  },
  {
    title: 'Reforma Constitucional',
    data: 'reformaConstitucional',
    titleGenre: 'f',
  },
];
const processingResultList = [
  { name: 'Aprobada', src: aprobado },
  { name: 'Caducada', src: noaprobado },
  { name: 'Convalidada', src: aprobado },
  { name: 'Convalidada y tramitada como proyecto de ley', src: aprobado },
  { name: 'Derogada', src: noaprobado },
  { name: 'Decaída', src: noaprobado },
  { name: 'En tramitación', src: tramitacion },
  { name: 'Inadmitida a trámite', src: noaprobado },
  { name: 'Rechazada', src: noaprobado },
  { name: 'Retirada', src: noaprobado },
  { name: 'Otros', src: noaprobado },
];

const sortByList = [
  { name: 'Relevancia', searchName: 'score' },
  { name: 'Año', searchName: 'updated' },
];

const euDrivenList = [
  { name: 'Sí', data: 'true' },
  { name: 'No', data: 'false' },
];

const sortOptions = {
  'Últimas leyes': 'updated',
  'Leyes del Gobierno': 'gobierno',
  'Leyes de Grupos Parlamentarios': 'group_parlamentary',
  'Leyes impulsadas por Europa': 'eu_driven',
};

export { initiativesList, filterByList, mainModal, modalButtons, processingResultList, sortOptions, sortByList, euDrivenList };
