import React, { useState, useRef, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { fadeIn } from 'utils/functions';

import SingleCard from 'components/SingleInitiative/SingleCard';

import useSlug from 'hooks/SingleInitiative/useSlug';
import useNavScroll from 'hooks/SingleInitiative/useNavScroll';
import useBodyClass from 'hooks/useBodyClass';
import useFilterModal from 'hooks/SingleInitiative/useFilterModal';
import useAverageStage from 'hooks/SingleInitiative/useAverageStage';

import { SEO } from 'elements/SEO';
import ModalInfo from 'elements/Modals/ModalInfo';
import Summary from 'elements/SingleInitiative/Summary';
import ModalAuthor from 'elements/Modals/ModalAuthor';
import StagesMenu from 'elements/SingleInitiative/StagesMenu';
import StagesMenuMB from 'elements/SingleInitiative/StagesMenuMB';
import useStagesNavMB from 'hooks/SingleInitiative/useStagesNavMB';
import EditorSummary from 'elements/SingleInitiative/EditorSummary';

import LegislatureContext from 'context/LegislatureContext';

export default function SingleInitiative(props) {
  const history = useHistory();
  const [singleIn, setSingleIn] = useState('');
  const [isFetched, setIsFetched] = useState(false);
  const { handleOpenModal, handleIsBurguerOpen } = props;
  const legislatureSlug = props.match.params.legislatureId;
  const [legislatureName, setLegislatureName] = useState('');
  const { legislaturesList } = useContext(LegislatureContext);

  useEffect(() => {
    const invertSlug = legislatureSlug?.split('-')[1] + '-' + legislatureSlug?.split('-')[0];
    const legislature = legislaturesList?.find(x => {
      return x.slug === invertSlug;
    });
    if (!legislature || legislature.id === legislaturesList[0].id) {
      setLegislatureName('Legislatura actual');
    } else {
      const legNameNumber = legislature?.name.split(' ')[0];
      const legNameName = legislature?.name.split(' ')[1];
      setLegislatureName(legNameName + ' ' + legNameNumber);
    }
  }, [legislatureSlug, legislaturesList]);

  /*
   *
   * Fetch data
   *
   */
  useSlug({
    setSingleIn,
    setIsFetched,
    urlID: props.match.params.id,
  });
  useAverageStage();

  /*
   *
   * Set BodyClass
   *
   */
  useBodyClass('single', handleIsBurguerOpen);

  /*
   *
   * Set initiative modal
   *
   */
  const modal = useFilterModal({ isFetched, singleIn });

  /*
   *
   * Set author modal
   *
   */
  const authorRef = useRef(null);
  const handleOpenAuthor = () => {
    if (singleIn?.authors?.length > 1) {
      fadeIn(authorRef.current);
    }
  };

  /*
   *
   * Mobile Stages Menu
   *
   */
  const { isStagesMenuOpen, handleStagesMenuClick, handleCloseStagesMenu } = useStagesNavMB();

  /*
   *
   * TitleNav Scroll
   *
   */
  const { handleNavClick, activeTitleNav, resumen, titleNavRef } = useNavScroll({
    singleIn,
    handleCloseStagesMenu,
  });

  const handleGoBackClick = () => {
    pathBack && pathBack !== '' ? history.push(pathBack) : history.goBack();
  };

  const title = () => {
    return singleIn.titleAlias ? singleIn.titleAlias : singleIn.title;
  };

  /*
   *
   * Override path where goes back button
   *
   */
  const [pathBack, setPathBack] = useState('');
  useEffect(() => {
    history.action === 'POP' ? setPathBack('/buscador-de-leyes') : setPathBack('');
  }, [pathBack, history]);

  return (
    <div className='b-single'>
      <SEO title={title()} description='Parlamentia - Newtral' url={window.location.href} creator='' />

      <div className='b-single__header hidden-xs'>
        <div className='container'>
          <button className='e-button-back c-title c-title--xxl' onClick={handleGoBackClick}>
            Detalle de ley
          </button>
        </div>
      </div>

      <StagesMenuMB
        activeTitleNav={activeTitleNav}
        isStagesMenuOpen={isStagesMenuOpen}
        handleStagesMenuClick={handleStagesMenuClick}
        handleGoBackClick={handleGoBackClick}
      />

      <div className='container'>
        <StagesMenu activeTitleNav={activeTitleNav} handleNavClick={handleNavClick} singleIn={singleIn} amendments={singleIn.amendments} />

        {isFetched && (
          <div className='b-single__main'>
            <Summary ref={resumen} singleIn={singleIn} handleOpenAuthor={handleOpenAuthor} legislatureName={legislatureName} />

            {singleIn.summary && singleIn.summary !== '' && <EditorSummary singleIn={singleIn} />}

            {/* <ValidatedCard isFetched={isFetched} singleIn={singleIn} /> */}

            <div className='b-single__inner'>
              {modal && <ModalInfo title={modal.title} titleGenre={modal.titleGenre} data={modal.data} handleOpenModal={handleOpenModal} />}
            </div>

            {singleIn.stages &&
              singleIn.stages
                .filter(eachOne => {
                  const title = eachOne.state;
                  return title !== '';
                })
                .map((eachOne, i) => {
                  const title = eachOne.state;
                  return (
                    <SingleCard
                      key={eachOne.detail + '_' + singleIn.id + i}
                      info={eachOne}
                      path={singleIn}
                      title={title}
                      navId={title + i}
                      ref={titleNavRef.current[i]}
                    />
                  );
                })}
          </div>
        )}
        <div className='b-single__sidebar'>
          {modal && <ModalInfo title={modal.title} titleGenre={modal.titleGenre} data={modal.data} handleOpenModal={handleOpenModal} />}
        </div>
      </div>
      {singleIn?.authors?.length > 1 && <ModalAuthor ref={authorRef} authors={singleIn.authors} />}
    </div>
  );
}
